.archive-body {
  background-color: #f9fafb;
  margin: auto;
  width: 100%;
  max-height: 500px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 10px 10px;
  overflow-y: scroll;

  .archive-inner-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    justify-content: flex-end;
    align-items: flex-end;
    margin: auto;
  }
}
.archive-body::-webkit-scrollbar {
  display: none;
}
