@import '../../assets/scss/varialbles';

.bg-img {
  // background-image: linear-gradient(0deg, rgba(17, 25, 40, 0.75), rgba(17, 25, 40, 0.75)), url('../../assets/images/bg.jpg');
  background-image: url('../../assets/images/bg-main.png');
  background-image: url('../../assets/images/Sing-up-page-background.png');
  height: 100vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
  }

  .title {
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    color: #111928;
  }

  .ant-card-body {
    width: 415px;
  }

  .ant-form-item-label {
    color: #111928;
    font-size: 14px;
    padding: 0 0 5px;
    label {
      font-weight: 500;
    }
  }

  .cgray {
    color: #4b5563;
  }
  .ant-divider {
    margin: 20px 0px;
  }
}
