@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
@import 'antd/dist/reset.css';
@import './assets/scss/varialbles';
@import './assets/scss/style';
@import './assets/scss/dashboard.scss';
@import './assets/scss/public.scss';
@import './assets/scss/question.scss';
@import './assets/scss/requests.scss';
@import './assets/scss/assignedToCard.scss';
@import './assets/scss/_modal.scss';
@import './assets/scss/admin.scss';
@import './assets/scss/expertRequest.scss';
@import './assets/scss/messaging.scss';
@import './assets/scss/expertDetail.scss';
@import './assets/scss/findAnExpertDetail.scss';
@import './assets/scss/reviewModal.scss';
@import './assets/scss/assignToExpertModal.scss';
@import './assets/scss/archive.scss';
@import './assets/scss/imagePreview.scss';
@import './assets/scss/radioButtonImage.scss';
@import './assets/scss/capsule.scss';
@import './assets/scss/serviceAndRates.scss';
@import './assets/scss/addAnotherCard.scss';
@import './assets/scss/blogs.scss';
@import './assets/scss/custom.scss';


body {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  background-color: #f9fafb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  // display: none;
}
// *{
//   box-sizing: border-box;
//   margin: 0 !important;
//   padding: 0 !important;
// }
code {
  font-family: 'Inter', sans-serif !important;
}

.frozen-expert-row {
  color: map-get($colors, 'secondary');
}

.ai-button {
  color: white;
  font-weight: 500;
  background: rgb(89, 69, 138);
  background: linear-gradient(
    90deg,
    rgba(89, 69, 138, 1) 0%,
    rgba(157, 66, 235, 1) 35%,
    rgba(228, 57, 237, 1) 100%
  );
}
