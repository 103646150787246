/* Override the antd defaults*/

// override select
.ant-select-arrow {
  color: black !important;
}

.ant-select-arrow,
.ant-select-selection-search,
.ant-select-clear {
  font-size: 1rem !important;
}

.ant-layout {
  background-color: transparent;
  // min-width: fit-content;
  min-width: 100%;
}

.ant-form-item {
  margin-bottom: 24px;
}

.ant-form-item-label {
  color: #111928;
  font-size: 14px;
  padding: 0 0 5px;

  label {
    font-weight: 500;
  }
}

.ant-btn-primary:disabled {
  background-color: #1c64f2;
  color: white;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-card-body {
  box-shadow: $shadow;
}

/* End Overrigde the antd defaults*/

.password-popup-main {
  padding-top: 10px;
  padding-bottom: 5px;

  p {
    margin-bottom: 0px !important;
  }

  .red {
    color: red;
  }

  .green {
    color: green;
  }

  .gray {
    color: rgba(0, 0, 0, 0.75);
  }
}

/** Header related Style**/
.pointer {
  cursor: pointer;
}

.header {
  &.ant-layout-header {
    width: 100%;
    height: 75px;
    line-height: 75px;
    background-color: white;
    border-bottom: 1px solid $border-color;
    padding-inline: 75px;

    .ant-avatar {
      color: black;
    }
  }

  .right {
    float: right;
    width: 475px;

    .header-menu {
      margin-bottom: 0px;
      padding: 0px 15px;
      font-weight: 600;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      width: auto;
    }

    .ant-menu-overflow-item.ant-menu-item {
      font-weight: 600;
      color: white;
    }
  }

  .public-header {
    p {
      display: inline;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #111928;
      padding: 0px 20px;
    }
  }
}

/** End Header**/

/* Sidebar */
.site-layout-background {
  // background: #fff;
  background: #f9fafb;
}
.site-layout-background.white-bg {
  background: #ffffff;
}

.ant-layout-sider {
  border-right: 2px solid transparent;
  border-image-slice: 1;
}

.ant-menu-item {
  padding-left: 25px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-menu-item.ant-menu-item-selected::after {
  border-right: 2px solid transparent;
  border-image-slice: 1;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: linear-gradient(90deg,
      rgba(158, 43, 133, 0.3) 0%,
      rgba(158, 43, 133, 0.3) 40%,
      rgba(53, 51, 170, 0.3) 100%) !important;
  font-weight: bold;
  color: black;
}

.ant-layout-has-sider {
  min-height: calc(100vh - 64px);
}

.help-text {
  color: #4b5563;
  font-size: 12px !important;
  font-weight: 500;
}

.m-title {
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: #111928;

  &.f20 {
    font-size: 20px;
  }
}

.review-card {
  .ant-rate-star-full {
    svg {
      path {
        fill: #dbb800;
      }
    }
  }

  .review-text {
    font-size: 14px;
    line-height: 150%;
    color: #6b7280;
  }

  .review-name {
    font-weight: bold;
  }

  .review-sub-name {
    color: #6b7280;
  }
}

.otp-modal {
  background-color: red;

  input {
    width: 35px !important;
    height: 35px;
    border: 2px solid #eee;
    font-size: 20px;
  }

  .otp-section {
    align-items: center;
    justify-content: center;
    display: flex;

    span {
      color: #eee;
    }
  }

  .ant-modal-body {
    text-align: center;
  }

  .link {
    color: #1c64f2;
    font-weight: bold;
  }
}

.divider {
  height: 1px;
  background: #d1d5db;
  margin-top: 40px;
  margin-bottom: 45px;
}

/**** Start Footer ****/
.public-footer {

  a,
  .footer-contact,
  .footer-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #6b7280;
  }

  .footer-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: #111928;
  }
}

/**** End Footer ****/

.print-invoice {
  padding: 0px 100px;
  margin-bottom: 20px;

  .parent {
    display: flex;
    width: 100%;

    .left,
    .right {
      width: 50%;

      p {
        margin-bottom: 0px;
      }
    }

    img {
      width: 320px;
    }
  }

  .table1 {
    table {
      width: 100%;
      text-align: left;

      tr th {
        background-color: #1b35b3;
        color: white;
      }

      tr th,
      tr td {
        padding: 0px 10px;
      }
    }
  }
}

.notification_bell {
  cursor: pointer;
  font-size: 22px;
  margin-right: 10px;
}


.geminiTypewriterClassForBlueText {
  font-size: 1.5rem;
  color: #ababab;
}

.geminiTypewriterCursorClassForBlurText {
  color: #ababab;
}

.text-to-blur {
  position: absolute;
  backdrop-filter: blur(4px);
  --webkit-backdrop-filter: blur(4px);
  --moz-backdrop-filter: blur(4px);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 20px;
}
