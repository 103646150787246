.ac-heading {
  font-family: Inter, sans-serif !important;
  font-size: 2.5rem !important;
  font-weight: 400 !important;
  letter-spacing: -0.01em !important;
  line-height: 3.5rem !important;
}
.hiw-heading {
  font-size: clamp(1.8rem, 2.9vw,2.5rem) !important;
}

.how-it-work .m-title{
  font-size: clamp(.8rem, 5vw, 2.5rem) !important;
  line-height: 2rem !important;
}
.public .faq .ant-collapse-item.ac-heading .ant-collapse-header-text {
  font-family: Inter, sans-serif !important;
  font-size: 2.5rem !important;
  font-weight: 400 !important;
  letter-spacing: -0.01em !important;
  line-height: 3.5rem !important;
}

.ac-sub-heading {
  font-family: Inter, sans-serif !important;
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.ac-content {
  font-family: Inter, sans-serif !important;
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}
.hiw-content {
  font-size: clamp(1.1rem, 1.7vw, 1.5rem) !important;
}

.ac-bg-light {
  background: #ffffff !important;
}

.ac-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  pointer-events: none;
  /* Makes the overlay non-interactive */
  z-index: 1;
}

.ac-playicon {
  height: 70px;
  width: 70px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(28, 100, 242, 0.8);
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;

  svg {
    width: 54px;
    height: 54px;
    color: rgba(255, 255, 255, 0.8);
    transform: translate(4px);
  }
}

.rotate-card-container {
  width: 30%;
}

.rotate-card {
  position: relative;
  width: 100%;
  height: 325px;
  color: white;
  cursor: pointer;
  transition: 0.8s ease-in-out;
  transform-style: preserve-3d;
  box-shadow: 0 0 38px #0000001a;
  border-radius: 10px;

  &:hover {
    transform: rotateY(0.5turn);
  }

  .card__face {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: 0.8s ease-in-out;
    background-color: #ffffff;
    border-radius: 10px;

    // -webkit-box-reflect: below 0
    //   linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.4));

    img {
      object-fit: cover;
    }

    &--back {
      transform: rotateY(0.5turn);
    }
  }

  .sub-text {
    font-size: 15px;
    font-weight: 500;
    color: #6b7280;
    line-height: 160%;
    text-align: center;
  }

  .title {
    font-size: 1.2rem;
    font-weight: 800;
    letter-spacing: -0.01em;
    color: #111928;
  }
}

.benefit-expert-card-front-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
  }

  .title {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
  }
}

.benefit-expert-card-back-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .desc {
    font-size: 1.5rem !important;
    text-align: left !important;
    padding: 0 5%;
  }
}

.steps-num-box {
  // width: 7.5rem;
  width: clamp(4rem, 9vw, 6rem);
  height:clamp(4rem, 9vw, 6rem);
 

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1c64f2;
  color: white;
  border-radius: 20%;

  .steps-num {
    font-size: 5rem;
    font-size: clamp(1rem, 5vw, 6rem);
    font-weight: 800;
  }
}

.steps-num-box.steps-num-box-black {
  background-color: black;
}

.login-card {
  .ant-card {
    height: 100%;
    box-shadow:
      0 1px 4px 0 rgba(0, 0, 0, 0.33),
      0 1px 6px -1px rgba(0, 0, 0, 0.02),
      0 2px 4px 0 rgba(0, 0, 0, 0.02) !important;
  }

  .ant-card-body {
    height: 100%;
    width: 25vw;
    width: clamp(300px, 30vw, 550px);

    .radio-button-image-list {
      // img {
      //   width: 150px;
      //   height: 150px;
      // }
    }
  }
}

.faq-page {
  padding-top: 75px;
}

.login-side-img-container {
  .ac-heading {
    font-size: clamp(1rem, 1.3vw, 2.5rem) !important;
    font-weight: 600 !important;
    width: 80%;
    line-height: 2.2rem !important;
  }

  .ac-content {
    font-size: clamp(0.8rem, 0.8vw, 1.5rem) !important;
    width: 80%;
  }    
}

.working-steps-card {
  border-radius: 15px !important;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  .ant-card-body {
    border-radius: 15px !important;
    box-shadow: 0 0 38px rgba(0, 0, 0, 0.1019607843) !important;
    @media screen and (max-width: $small) {
      padding: 10px 0px !important;
    }
  }
}

.line-container {
  display: flex;
  justify-content: center;

  .line {
    width: 5px;
    height: 10vh;
    background-color: #1c64f2;
  }
}

.play-icon {
  font-size: 4rem;
  color: #1877f2;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  :hover {
    color: #67a1ee;
  }
}

.sub-title_home {
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 150%;
  color: #6b7280;
}


/* Webkit-based browsers (Chrome, Safari, Edge, etc.) */
.clamped-text {
  display: -webkit-box !important; /* Used for multi-line truncation */
  -webkit-line-clamp: 2; /* Limits the text to 3 lines */
  -webkit-box-orient: vertical; /* Ensures text is arranged vertically */
  overflow: hidden; /* Hides text that exceeds the number of lines */
  text-overflow: ellipsis; /* Adds ellipsis (...) for overflow text */
}

/* Firefox fallback using display: block (for one-line truncation) */
@-moz-document url-prefix() {
  .clamped-text {
    display: block; /* This will make the element behave normally in Firefox */
    overflow: hidden;
    text-overflow: ellipsis; /* Adds ellipsis if the text overflows */
    white-space: nowrap; /* Ensures truncation happens on single-line */
  }
}

/* Fallback for Internet Explorer & older browsers (single-line truncation) */
.clamped-text {
  display: block; /* Default block display */
  white-space: nowrap; /* Prevents wrapping and truncates text */
  overflow: hidden; /* Hides the overflowed content */
  text-overflow: ellipsis; /* Adds ellipsis to truncated text */
}

/* For other older browsers (or those that do not support -webkit-line-clamp) */
.clamped-text {
  display: block; /* Ensures basic block layout */
  white-space: normal; /* Allows wrapping of text */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds ellipsis for overflow */
}