.custom-modal {
  .custom-modal-terms {
    border: 1px solid #c7c7c7;
    border-radius: 8px;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #111928;
  }

  .sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #6b7280;
  }

  ::-webkit-scrollbar {
    // display: none;
  }
}

.payment-modal {
  .ant-modal-content {
    padding: 0px;
  }

  .ant-modal-header {
    padding: 15px 20px;
    border-bottom: 1px solid #e5e7eb;
  }

  .ant-modal-body {
    padding: 15px 20px;
  }

  .ant-modal-footer {
    padding: 15px 20px;
    border-top: 1px solid #e5e7eb;
  }

  &.question-modal {
    label {
      font-weight: 600;
      font-size: 16px;
      color: #111928;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }

  &.quotation-modal {
    label {
      font-weight: 600;
      font-size: 14px;
      color: #111928;
    }
  }

  .ant-picker-input > input[disabled],
  .ant-input[disabled],
  .ant-select-disabled .ant-select-selector {
    color: rgb(0 0 0 / 90%) !important;
  }

  .payment-section {
    .ant-form-item-control-input {
      border: 2px solid #eee;
      border-radius: 8px;
      padding: 10px;
    }

    .pay-btn {
      justify-content: flex-end;
    }
  }
}

.evaluation-report-modal {
  .ant-modal-content {
    // max-height: 95vh;
    // width: 80%!important;
    height: 95vh;
    overflow: hidden;
    padding: 10px !important;
    border: 10px solid white;
  }
}

.ai-generated-report-modal {
  .ant-modal-content {
    padding: 20px 24px 0 20px !important;
  }
}

.custom-ant-evaluation-report-modal-content {
  position: relative !important;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  border-radius: 4px !important;
  padding: 0 !important;
  min-height: 25vh;
  min-width: 35vw;
  background: none !important;
  box-shadow: none !important;
  overflow: hidden !important;
  border: none !important;
}

.custom-ant-evaluation-report-modal-content div.resize-handle {
  position: absolute;
  background: rgb(28, 100, 242);
  z-index: 10;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.custom-ant-evaluation-report-modal-content div.resize-handle.top {
  top: 0;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translateX(-50%);
  cursor: n-resize;
}

.custom-ant-evaluation-report-modal-content div.resize-handle.bottom {
  bottom: 0;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translateX(-50%);
  cursor: s-resize;
}

.custom-ant-evaluation-report-modal-content div.resize-handle.left {
  top: 50%;
  left: 0;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  cursor: w-resize;
}

.custom-ant-evaluation-report-modal-content div.resize-handle.right {
  top: 50%;
  right: 0;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  cursor: e-resize;
}
.evaluation-modal-header {
  display: flex;
  justify-content: space-between;
}
.text-size-buttons {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-right: 3rem;
}
.text-size-buttons div {
  cursor: pointer;
  font-size: 1.3rem;
}
.evaluation-modal-inner-text {
  overflow: auto;
  height: 100%;
}
