$small: 576px;
$medium: 768px;
$large: 992px;
$extraLarge: 1316px;

.public {
  // background-image: url('../../assets/images/bg-main.png');
  // height: 100vh;
  /* Center and scale the image nicely */
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  position: relative;

  .padding-around {
    padding: 0 75px;
    @media screen and (max-width: $medium) {
      padding: 20px;
    }

    &.about {
      .cover-img {
        img {
          width: 100%;
          background-size: cover;
        }
      }

      .about-section {
        .benefits-image-wrapper img {
          box-shadow: 0 0 38px rgba(0, 0, 0, 0.1019607843);
          border-radius: 10px;
        }

        .typewriterWrapperClass {
          font-size: 24px;
          line-height: 2rem;
        }

        // text-align: center;
        .team-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 100px;
        }

        .blue-line {
          width: 4.8rem;
          border-top: 2px solid #147ac9;
          // margin: auto;
        }

        .sectionImg {
          width: 100%;
          object-fit: cover;
          height: 100%;
        }

        .scene {
          // width: 1000px;
          width: 100%;
          display: flex;
          justify-content: start;
          // column-gap: 2rem;
          perspective: 800px;
          flex-wrap: wrap;
          // row-gap: 15px;

          .card-container {
            padding: 25px;
            // width: 30%;
          }

          .card {
            position: relative;
            width: 100%;
            height: 340px;
            color: white;
            cursor: pointer;
            transition: 0.8s ease-in-out;
            transform-style: preserve-3d;
            box-shadow: 0 0 38px #0000001a;
            border-radius: 10px;

            &:hover {
              transform: rotateY(0.5turn);
            }

            .card__face {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              backface-visibility: hidden;
              transition: 0.8s ease-in-out;
              background-color: #ffffff;
              border-radius: 10px;

              // -webkit-box-reflect: below 0
              //   linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.4));

              img {
                object-fit: cover;
              }

              &--back {
                transform: rotateY(0.5turn);
              }
            }

            .sub-text {
              font-size: 15px;
              font-weight: 500;
              color: #6b7280;
              line-height: 160%;
              text-align: center;
            }

            .title {
              font-size: 1.2rem;
              font-weight: 800;
              letter-spacing: -0.01em;
              color: #111928;
            }
          }
        }

        .title {
          font-size: 2.5rem;
          font-weight: 400;
          color: black;
          letter-spacing: -0.01em;
          line-height: 3.5rem;
        }

        .sub-text {
          font-weight: 400;
          font-size: 1.7rem;
          line-height: 160%;
          // text-align: justify;
          color: black;
          font-size: clamp(1.2rem, 1.84vw, 1.5rem);
        }

        .meet {
          .title {
            font-size: 30px;
          }

          .sub-text {
            width: 500px;
            margin: 0 auto;
          }
        }

        .meet-cards {
          text-align: center;
          padding: 30px 10px;

          img {
            margin: 0 auto;
            border-radius: 50%;
            border: 1px solid #6b7280;
          }

          .title {
            font-size: 1.2rem;
          }

          .sub-text {
            font-size: 15px;
            font-weight: 500;
          }
        }

        .meet-cards_about {
          padding: 10px;
          justify-items: center;
          text-align: start;

          .title {
            font-size: 1.2rem;
          }

          .sub-text {
            font-size: 13px;
            font-weight: 500;
            text-align: start;
          }
        }
      }
    }

    .faq-page {
      width: 70%;
      margin: 0 auto;
      border-bottom: 2px solid #e5e7eb;
    }
    @media screen and (max-width: $large) {
      .faq-page {
        width: 100%;
        margin: 0 auto;
        border-bottom: 2px solid #e5e7eb;
      }
    }

    &.border {
      padding-bottom: 0px !important;
    }

    &.contact-faq {
      padding-top: 7rem !important;
      padding-bottom: 7rem !important;

      .contact-section {
        width: 75%;
        margin: 0 auto;
        text-align: center;
        padding-top: 0px !important;

        .title {
          font-size: 18px;
          line-height: 150%;
          text-align: center;
          color: #111928;
          font-weight: 600;
        }

        .sub-title {
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #9ca3af;
          width: 70%;
          margin: 0 auto;
        }
      }
    }

    &.section6 {
      .ant-card,
      .ant-card-body {
        border-radius: 24px;
      }

      img {
        border-radius: 8px;
      }

      .title {
        font-weight: 800;
        font-size: 32px;
        line-height: 125%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #ffffff;
      }

      .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
      }

      .ant-card-body {
        background: #1c64f2;
        padding: 40px;
      }
    }

    .border-bottom {
      width: 100%;
      height: 1px;
      background: #e5e7eb;
      margin: 0 auto;
    }

    &.find-expert {
      .beta-warning {
        height: 75px;
      }

      .help-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        color: #111928;
      }

      .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #6b7280;
      }

      .card-parent {
        cursor: pointer;

        .main-img {
          width: 100%;
          height: 275px;
          object-fit: cover;
          border-radius: 8px;
        }

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          color: #111928;
        }

        .cat-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #6b7280;
        }

        .card-section2 {
          width: 100%;
          display: flex;
          align-items: center;
          height: 30px;

          p {
            font-size: 14px;
            line-height: 150%;
            color: #6b7280;
            margin-bottom: 0;
          }

          img {
            margin-right: 10px;
            width: 20px;
          }
        }
      }
    }
  }

  .contact {
    .cover-img {
      background-image: url('../../assets/images/bg-main.png');
      height: 75vh;
      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      @media screen and (max-width: $large) {
        height: auto;
      }
    }

    .card-wrapper {
      width: 800px;
      margin-top: 100px;
      margin-bottom: 100px;
    }

    .sub-title {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #9ca3af;
    }

    .title {
      color: white;
      font-weight: 800;
      font-size: 32px;
    }

    .tc-text {
      color: #6b7280;
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 25px;
    }

    .email-secion {
      background-color: #ffffff;
      width: 50%;
      margin: 0 auto;
      margin-bottom: 100px;
    }
    @media screen and (max-width: $large) {
      .email-secion{
        width: 100%;
        padding-top: 5rem;
      }
      
    }

    .section1 {
      width: 400px;
      text-align: center;
      margin-bottom: 100px;
    }

    .line1 {
      margin-top: 20px;
      color: #111928;
      font-weight: 700;
      font-size: 20px;
    }

    .line2 {
      font-style: normal;
      font-weight: 350;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #6b7280;
    }

    .line3 {
      color: #1c64f2;
      font-weight: bold;
    }
  }

  .home-page {
    // width: fit-content;
    width: 100%;

    .cover-img {
      height: 95vh;
      background-image: url('../../assets/images/Sing-up-page-background.png');
      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @media screen and (max-width: $large) {
        height: auto;
      }
    }

    .expert-home {
      position: relative;
      background-image: url('../../assets/images/expertIndexBgWhite.jpg');
    }

    // .expert-home::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   // background: rgba(0, 0, 0, 0.1);
    // }

    .text-on-img {
      display: flex;
      align-items: center;
      background-image: url('../../assets/images/Sing-up-page-background.png');
      height: 100%;
      z-index: 2;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      .section {
        color: white;
        width: 100%;
        // padding: 0px 177px;
        padding: 0px 75px;
        padding: 0px clamp(25px, 5vw, 75px);

        .ant-card-body {
          position: absolute;
          inset: 0px 0px 0px 0px;
          padding: 20px;
          width: 82%;
          min-height: 89%;
          max-height: 89%;
        }
      }

      .looking-box {
        padding-block: 12px;
        min-height: 100%;
        width: 80%;

        .ant-tooltip-inner {
          color: yellow;
          background-color: green;
        }
      }

      .img-title {
        font-weight: 700;
        font-size: 46px;
        line-height: 60px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: black;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 15px;

        @media screen and (max-width: $extraLarge) {
          font-size: 22px;
          line-height: 30px;
        }

        @media screen and (max-width: $medium) {
          font-size: 22px;
          line-height: 30px;
        }

        @media screen and (max-width: $large) {
          font-size: 22px;
          line-height: 30px;
        }
      }

      .img-subtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: black;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 45px;

        @media screen and (max-width: $large) {
          font-size: 17px !important;
        }
        // @media screen and (max-width: $medium) {
        //   font-size: 15px !important;
        // }
        // @media screen and (max-width: $small) {
        //   font-size: 13px !important;
        // }
      }

      .img-card {
        .ant-card-body {
          min-height: 200px;
        }
      }
    }

    .h-title {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 125%;
      letter-spacing: -0.01em;
      color: #111928;

      @media screen and (min-width: $extraLarge) {
        font-size: 36px;
      }
    }

    .section2 {
      padding: clamp(25px, 5vw, 75px);

      .offering {
        display: flex;

        .video-react-controls-enabled {
          padding: 0;
          background-color: #1c64f2;
        }
      }

      .offering-left {
        padding-right: 50px;
      }

      .offering-right {
        padding-left: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .box {
        display: flex;
        align-items: flex-start;

        img {
          margin-right: 20px;
        }

        .title {
          font-weight: 700;
          font-size: 18px;
          line-height: 150%;
          color: #111928;
        }

        .sub-title {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #6b7280;
        }
      }
    }

    .section3 {
      padding: clamp(25px, 5vw, 75px);
      background: #f9fafb;

      .react-vimeo-player-modal {
        width: fit-content;
        height: inherit;
        background-color: #1c64f2;
      }

      .sub-title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #6b7280;
        width: 500px;
        margin: 0 auto;
      }

      .box {
        display: flex;

        .ant-card-body {
          min-height: 230px;
        }

        .title {
          font-weight: 600;
          font-size: 16px;
          line-height: 125%;
          color: #111928;
        }

        .desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #6b7280;
        }
      }

      .benefit {
        .tc {
          img {
            cursor: pointer;
          }
        }
      }
    }

    .section4 {
      padding: clamp(25px, 5vw, 75px);
      display: flex;
      flex-direction: column;

      .react-vimeo-player-modal {
        width: fit-content;
        height: inherit;
        background-color: #1c64f2;
      }

      .sub-title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #6b7280;
        margin: 0 auto;
      }

      .benefits-row-container {
        @media screen and (max-width: $small) {
          gap: 30px;
        }
      }

      .benefits-image-wrapper {
        @media screen and (max-width: $small) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .benefits-description-wrapper {
        @media screen and (max-width: $large) {
          // max-width: 70%;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          padding: 20px;
        }
      }

      .offering {
        display: flex;
        align-items: center;
      }

      .offering-left {
        padding-right: 50px;
      }

      .offering-right {
        padding-left: 50px;
        width: 100%;
        text-align: right;
      }

      .benefits-sub-text {
        max-width: 100%;

        @media screen and (max-width: $small) {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .m-title {
          font-size: 30px;
          text-align: left;
          font-weight: 700;
        }

        .sub-title {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #6b7280;
          text-align: left;

          @media screen and (max-width: $small) {
            // text-align: center;
          }

          &.heading {
            margin: 0 auto;
            text-align: center;
          }
        }
      }

      @media screen and (min-width: $small) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .section5 {
      padding: clamp(25px, 5vw, 75px);
      background: #f9fafb;

      .benefit {
        width: 70%;
        margin: 0 auto;
        @media screen and (max-width: $large) {
          width: 100%;
        }
      }

      .sub-title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #6b7280;
        width: 500px;
        margin: 0 auto;
      }
    }

    .section7 {
      padding: clamp(25px, 5vw, 75px);
      background: #f9fafb;

      .sub-title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #6b7280;
        width: 500px;
        margin: 0 auto;
      }

      .box {
        max-width: inherit;
        overflow-x: scroll;
      }

      ::-webkit-scrollbar {
        display: none;
      }

      .benefit {
        margin: 0 auto;

        .review-card-parent {
          padding: 0px 20px;
          min-width: 400px;
        }
      }

      .f30 {
        font-size: 30px;
      }

      .s-title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #6b7280;
        width: 500px;
        margin: 0 auto;
      }
    }

    .section6 {
      padding: clamp(25px, 5vw, 75px);

      .ant-card,
      .ant-card-body {
        border-radius: 24px;
      }

      img {
        border-radius: 8px;
      }

      .title {
        font-weight: 800;
        font-size: 32px;
        line-height: 125%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #ffffff;
      }

      .sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
      }

      .ant-card-body {
        background: #1c64f2;
        padding: 40px;
      }
    }
  }

  .faq {
    .ant-collapse-header-text {
      font-weight: 500;
      font-size: 1.5rem;
      color: #6b7280;
    }

    .ant-collapse-item-active {
      .ant-collapse-header-text {
        font-weight: 600;
        color: #111928;
      }
    }

    .ant-collapse-header {
      padding: 16px 16px;
    }

    .ant-collapse-content-box {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 150%;
      color: #6b7280;
    }

    .ant-collapse {
      background-color: transparent !important;
    }
  }
}

.public-footer {
  padding: 50px 75px !important;
  background: #f9fafb !important;
}
@media screen and (max-width: $medium) {
  .public-footer {
    padding: 25px !important;
  } 
}

.public-footer.white-bg {
  background: #ffffff !important;
}

.transfer {
  background: transparent;
  z-index: 9999;

  &.header {
    border-bottom: none;
    background-color: transparent;

    p {
      // color: white;
    }

    ul {
      li {
        color: white;
      }
    }

    .ant-avatar {
      background-color: white;
      background: white;
      border: 1px solid black;
    }
  }
}

.card-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 100%;

  .expert-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-width: 100%;
    gap: 10px;
    padding: 5px;
  }
}

.question_main_container {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-right: 55px;
}

.question_icon {
  font-size: 6rem;
  margin-bottom: 30px;
}

.Typewriter__wrapper {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
  color: black;
}

.Typewriter__cursor {
  color: black;
  font-size: 18px;
  font-weight: 700;
}

.box-1 {
  border: 2px solid #147ac9;
  padding: 20px;
  border-radius: 30px;
  // margin-left: 10px;
  cursor: pointer;
  // background-color: red;

  .box-title {
    font-size: 20px;
    color: #147ac9;
    font-weight: bold;
  }

  .box-text {
    font-size: 20px;
    color: black;
    font-weight: bold;
  }
}

.box-2 {
  border: 2px solid #147ac9;
  padding: 20px;
  border-radius: 30px;
  // background-color: greenyellow;
  width: 475px;
  cursor: pointer;

  .box-text {
    font-size: 20px;
    color: black;
    font-weight: bold;
  }
}

.footer_buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
}
.mobile-menu-item {
  min-height: 8%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding: 0px 10px;
  justify-content: space-between;
}

.menu-headings-font {
  font-size: 16px !important;
}
@media screen and (max-width: 1420px) {
  .menu-headings-font {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1350px) {
  .menu-headings-font {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1300px) {
  .menu-headings-font {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 1250px) {
  .menu-headings-font {
    font-size: 12px !important;
  }
}
.MsoNormal span {
  font-size: unset !important;
  line-height: 2rem !important;
}
p.p1 {
  font-family: Inter, sans-serif !important;
}
