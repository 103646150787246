.assign-to-expert-modal-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 10px;

  .assign-to-expert-modal-heading {
    font-size: larger;
    font-weight: 600;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    gap: 15px;
    flex-shrink: initial;
    white-space: nowrap;
    flex-wrap: nowrap;

    .modal-body-expert-card {
      display: flex;
      width: 100%;
      flex-direction: row;
      height: auto;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      align-items: start;
      padding: 10px;
      cursor: pointer;
      justify-content: space-between;
      background-color: white;

      .modal-body-expert-card-left-container {
        display: flex;
        flex-direction: row;
        width: 90%;
        // overflow: hidden;
        .modal-body-expert-card-image-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: auto;
          width: 60px;
          position: relative;
        }

        .modal-body-expert-card-image-wrapper img {
          border-radius: 40px;
        }

        .modal-body-expert-card-profile-wrapper {
          display: flex;
          max-width: 82%;
          flex-direction: column;
          justify-content: center;
          margin-left: 20px;

          .modal-body-expert-card-profile-name {
            font-size: 22px;
            font-weight: 600;
          }
          .modal-body-expert-card-profile-category {
            max-width: 100%;
            font-weight: 400;
            font-style: italic;
            color: #a0a0a0;
            white-space: initial;
          }
        }
      }
      .modal-body-expert-card-icon-wrapper {
        display: flex;
        justify-content:flex-start;
        align-items: center;
        width: auto;
        height: inherit;
        margin-top: 1rem;
      }
    }
  }

  .modal-body::-webkit-scrollbar {
    display: none;
  }

  .golden-experts-container {
    background-color: #f8ec7233;
    border: 2px solid #efbe1e;
    border-radius: 10px;
    gap: 5px;
    padding: 10px;
  }

  .assign-to-expert-footer-button-area {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    background: white;
    padding: 10px 10px 20px 10px;
  }
  .modal-body-expert-card-circle-box{
    display: flex;
  }
}
