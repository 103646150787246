.image-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: sans-serif;
}



.grid-container {
    columns: 3 200px;
    column-gap: 1.5rem;
    width: 90%;
    margin: 0 auto;
}

.grid-container div {
    width: 150px;
    margin: 0 1.5rem 1.5rem 0;
    display: block;
    break-inside: avoid;
    width: 100%;
    // border: solid 2px black;
    padding: 5px;
    // box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    transition: all 0.25s ease-in-out;
}

.grid-container div:hover img {
    // filter: grayscale(0);
    transform: scale(1.02);
}

.grid-container div:hover {
    // border-color: rgba(28,100,242,.9);
}

.grid-container div img {
    width: 100%;
    // filter: grayscale(100%);
    border-radius: 5px;
    transition: all 0.25s ease-in-out;
}

.grid-container div p {
    margin: 5px 0;
    padding: 0;
    text-align: center;
    font-style: italic;
}