.add-another-card {
  padding: 2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.25 s ease;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  span {
    text-decoration: underline;
    text-transform: capitalize;
    display: flex;
    gap: 0.5rem;
  }
}
